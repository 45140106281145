import React from "react";
import classnames from "classnames";

// STYLE
import "./routeListItem.scss";

// COMPONENTS
import { Row, Col } from "reactstrap";

import Icon from "../../atoms/Icon/Icon";
import PlainText from "../../atoms/PlainText/PlainText";
import Paragraph from "../../atoms/Paragraph/Paragraph";
import { useAuth } from "../../../hooks/redux";
import Button from "../../atoms/Button/Button";

/*
{
        navIcon: null,
        navLabel: "1,2 km",
        info: {
            title: "Nazwa zadania do wykonania",
            icon: null,
            shortDesc: "Skrócony opis zadania",
            Image: () => (
                <img
                    className="img-fluid"
                    src="https://images.unsplash.com/photo-1506744038136-46273834b3fb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                    alt="landscape-1"
                />
            ),
            Video: null,
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vitae ornare orci. Praesent et est mattis, vulputate lorem elementum, faucibus magna. Etiam faucibus mi sagittis euismod varius. Proin faucibus nibh mi. Sed vitae libero cursus nisl pharetra condimentum. Praesent at sem non neque tristique volutpat. Aenean porttitor vulputate placerat.",
        },
        completed: null,
        active: null,
    },
*/

const getIcon = (type) =>
    type === 3
        ? "icon-heart-rate-min"
        : type === 2
            ? "icon-bulb-min"
            : type === 1
                ? "icon-storytelling-min"
                : undefined;

const RouteListItem = ({
    index,
    route,
    places,
    place,
    setModal,
    onClick,
    setDistanceModal,
    mapRef,
}) => {
    const [auth] = useAuth();

    const isTaskCompleted = (place) =>
        auth?.data?.places?.find(
            (_) => _.route === route.id && _.place === place.id
        ) !== undefined;

    const isCompleted = isTaskCompleted(place);
    const isActive = (() => {
        for (const _place of route.places.filter((item) => item.type !== 4)) {
            if (!isTaskCompleted(_place)) {
                return _place.id === place.id;
            }
        }

        return false;
    })();

    return (
        <Row
            className={classnames("route-list-item--container", {
                "route-list-item--container__completed": isCompleted,
                "route-list-item--container__active": isActive,
            })}
            onClick={onClick}
        >
            <Col className="route-list-item--nav-container">
                {index < places.length - 1 && (
                    <div className="route-list-item--nav-line" />
                )}
                {index !== places.length - 1 ? (
                    <div className="route-list-item--nav-icon-with-label-container">
                        <div
                            className={`icon--container ${isCompleted ? "icon--container__completed" : ""
                                }`}
                        >
                            {index !== 0 && (
                                <Icon className={getIcon(place.type)} />
                            )}
                        </div>
                        <PlainText className="route-list-item--nav-label">
                            {index === 0 ? "Start" : place.navLabel}
                        </PlainText>
                    </div>
                ) : (
                    <div className="route-list-item--nav-icon-with-label-container">
                        <svg
                            className="last-item"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                opacity="0.3"
                                d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
                                fill="#39D480"
                            />
                            <path
                                d="M16 24C20.4183 24 24 20.4183 24 16C24 11.5817 20.4183 8 16 8C11.5817 8 8 11.5817 8 16C8 20.4183 11.5817 24 16 24Z"
                                fill="#39D480"
                            />
                            <path
                                d="M16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                )}
            </Col>
            <Col className="route-list-item--info-container">
                <Paragraph>{place.name}</Paragraph>
                {isActive && auth.isLoggedIn && place?.images[0]?.url && (
                    <div className="route-list-item--image__container">
                        <img
                            className="img-fluid route-list-item--image"
                            src={
                                process.env.STRAPI_API_URL +
                                place?.images[0]?.url
                            }
                            alt={place.name}
                        ></img>
                    </div>
                )}
                {/* {place.description && (
                    <div className="d-flex">
                        <Icon
                            className={getIcon(place.type)}
                            style={{ marginRight: "1rem" }}
                        />
                        <div className="w-100">
                            <Paragraph>{place.description}</Paragraph>
                        </div>
                    </div>
                )} */}
                {isActive && auth.isLoggedIn ? (
                    <Button
                        className="btn--primary w-100"
                        onClick={(_) => {
                            if (navigator && navigator.geolocation) {
                                navigator.geolocation.getCurrentPosition(
                                    (pos) => {
                                        const currentLocation = {
                                            lat: pos.coords.latitude,
                                            lng: pos.coords.longitude,
                                        };
                                        const placePosition = {
                                            lat: place.lon,
                                            lng: place.lat,
                                        };

                                        const distance = mapRef.distance(
                                            currentLocation,
                                            placePosition
                                        );

                                        if (distance > 2000) {
                                            setDistanceModal(true);
                                        } else {
                                            setModal(place);
                                        }
                                    },
                                    (error) => {
                                        console.log(error);
                                    },
                                    {
                                        enableHighAccuracy: true,
                                        timeout: 50000,
                                        maximumAge: 0,
                                    }
                                );
                            }
                        }}
                    >
                        {auth.isLoggedIn && !isCompleted
                            ? "Aktywuj wyzwanie"
                            : "Pokaż więcej"}
                    </Button>
                ) : (
                    ""
                )}
            </Col>
        </Row>
    );
};

export default RouteListItem;
