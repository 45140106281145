import React, { useState } from "react";

import Layout from "../layout";
import SEO from "../components/seo";

// ATOMS
import Button from "../components/atoms/Button/Button";
import Icon from "../components/atoms/Icon/Icon";
import Heading from "../components/atoms/Heading/Heading";
import Paragraph from "../components/atoms/Paragraph/Paragraph";
import PlainText from "../components/atoms/PlainText/PlainText";
import Tag from "../components/atoms/Tag/Tag";
import FormElement from "../components/atoms/FormElement/FormElement";

// MOLECULES
import TaskCard from "../components/molecules/TaskCard/TaskCard";
import RouteListItem from "../components/molecules/RouteListItem/RouteListItem";
import Modal from "../components/molecules/Modal/Modal";
import PageHeader from "../components/molecules/PageHeader/PageHeader";

// ORGANISMS
import RouteCards from "../components/organisms/RouteCards/RouteCards";

import { Container, Row, Col } from "reactstrap";

import RanksInfoModal from "../components/molecules/RanksInfoModal/RanksInfoModal";

const routesData = [
    {
        cardImage: {
            src: "https://images.unsplash.com/photo-1506744038136-46273834b3fb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
            alt: "landscape-1",
        },
        difficulty: "easy",
        likeCounter: "3",
        cardHeading: "Tutaj nazwa trasy do przebycia",
        cardTags: [
            {
                icon: "icon-pin-min",
                text: "Rynarzewo",
            },
            {
                icon: "icon-distance-min",
                text: "6 km",
            },
            {
                icon: "icon-bulb-min",
                text: "12 wyzwań",
            },
        ],
    },
    {
        cardImage: {
            src: "https://images.unsplash.com/photo-1506744038136-46273834b3fb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
            alt: "landscape-1",
        },
        difficulty: "medium",
        likeCounter: "3",
        cardHeading: "Tutaj nazwa trasy do przebycia",
        cardTags: [
            {
                icon: "icon-pin-min",
                text: "Rynarzewo",
            },
            {
                icon: "icon-distance-min",
                text: "6 km",
            },
            {
                icon: "icon-bulb-min",
                text: "12 wyzwań",
            },
        ],
    },
    {
        cardImage: {
            src: "https://images.unsplash.com/photo-1506744038136-46273834b3fb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
            alt: "landscape-1",
        },
        difficulty: "hard",
        likeCounter: "3",
        cardHeading: "Tutaj nazwa trasy do przebycia",
        cardTags: [
            {
                icon: "icon-pin-min",
                text: "Rynarzewo",
            },
            {
                icon: "icon-distance-min",
                text: "6 km",
            },
            {
                icon: "icon-bulb-min",
                text: "12 wyzwań",
            },
        ],
    },
];

const taskData = [
    {
        image: {
            src: "https://images.unsplash.com/photo-1506744038136-46273834b3fb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
            alt: "landscape-1",
        },
        cardTitle: "Tajemnicza nazwa zadania",
        cardDesc: "Lorem ipsum dolor sit amet, consectetur.",
    },
    {
        image: {
            src: "https://images.unsplash.com/photo-1506744038136-46273834b3fb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
            alt: "landscape-1",
        },
        cardTitle: "Tajemnicza nazwa zadania",
        cardDesc: "Lorem ipsum dolor sit amet, consectetur.",
    },
    {
        image: {
            src: "https://images.unsplash.com/photo-1506744038136-46273834b3fb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
            alt: "landscape-1",
        },
        cardTitle: "Tajemnicza nazwa zadania",
        cardDesc: "Lorem ipsum dolor sit amet, consectetur.",
    },
];

const routeTasksData = [
    {
        navIcon: "icon-checkmark-min",
        navLabel: "Start",
        info: {
            title: "Nazwa zadania do wykonania",
            icon: "icon-bulb-min",
            shortDesc: "Skrócony opis zadania",
            Image: null,
            Video: null,
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vitae ornare orci. Praesent et est mattis, vulputate lorem elementum, faucibus magna. Etiam faucibus mi sagittis euismod varius. Proin faucibus nibh mi. Sed vitae libero cursus nisl pharetra condimentum. Praesent at sem non neque tristique volutpat. Aenean porttitor vulputate placerat.",
        },
        completed: true,
        active: null,
    },
    {
        navIcon: "icon-checkmark-min",
        navLabel: "1,2 km",
        info: {
            title: "Nazwa zadania do wykonania",
            icon: "icon-bulb-min",
            shortDesc: "Skrócony opis zadania",
            Image: null,
            Video: null,
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vitae ornare orci. Praesent et est mattis, vulputate lorem elementum, faucibus magna. Etiam faucibus mi sagittis euismod varius. Proin faucibus nibh mi. Sed vitae libero cursus nisl pharetra condimentum. Praesent at sem non neque tristique volutpat. Aenean porttitor vulputate placerat.",
        },
        completed: null,
        active: true,
    },
    {
        navIcon: null,
        navLabel: "1,2 km",
        info: {
            title: "Nazwa zadania do wykonania",
            icon: null,
            shortDesc: "Skrócony opis zadania",
            Image: () => (
                <img
                    className="img-fluid"
                    src="https://images.unsplash.com/photo-1506744038136-46273834b3fb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
                    alt="landscape-1"
                />
            ),
            Video: null,
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vitae ornare orci. Praesent et est mattis, vulputate lorem elementum, faucibus magna. Etiam faucibus mi sagittis euismod varius. Proin faucibus nibh mi. Sed vitae libero cursus nisl pharetra condimentum. Praesent at sem non neque tristique volutpat. Aenean porttitor vulputate placerat.",
        },
        completed: null,
        active: null,
    },
];

const Sandbox = () => {
    // STATE
    const [modal, setModal] = useState(false);
    const [ranksModal, setRanksModal] = useState(false);

    if (typeof window === "undefined") return <></>;

    return (
        <Layout>
            <SEO title="Sandbox" />
            <Container>
                <Row>
                    <Col>
                        <Button
                            className="btn--primary m-5"
                            onClick={(_) => setRanksModal(true)}
                        >
                            Otwórz statusy i punkty odkrywcy
                        </Button>

                        <RanksInfoModal
                            modal={ranksModal}
                            setModal={setRanksModal}
                        />
                    </Col>
                </Row>
            </Container>

            <Container className="pb-5">
                <Row className="d-flex justify-content-center mt-5">
                    <Col lg={10}>
                        <PlainText as="h1">Komponenty</PlainText>
                    </Col>
                </Row>

                {/* BUTTONS */}
                <Row className="d-flex justify-content-center mt-5">
                    <Col lg={10}>
                        <PlainText className="mb-3 d-block">Buttons</PlainText>

                        {/* PRIMARY BUTTON */}
                        <div className="mb-3">
                            <Button className="btn--primary">
                                Dopasuj trasę do siebie
                            </Button>
                        </div>
                        {/* PRIMARY BUTTON */}

                        {/* SECONDARY BUTTON */}
                        <div className="mb-3">
                            <Button className="btn--secondary">
                                <Icon className="icon-map-min" />
                                Mapa
                            </Button>
                        </div>
                        {/* SECONDARY BUTTON */}

                        {/* CIRCLE BUTTON WITH ICON */}
                        <div className="mb-3">
                            <Button className="btn--circle">
                                <Icon className="icon-discover-min icon--alt" />
                            </Button>
                        </div>
                        {/* CIRCLE BUTTON WITH ICON */}

                        {/* CIRCLE BUTTON WITH ICON && LABEL */}
                        <div className="mb-3">
                            <Button className="btn--secondary__sm">
                                <Icon className="icon-heart-min" />
                                <PlainText className="btn--label">3</PlainText>
                            </Button>
                        </div>
                        {/* CIRCLE BUTTON WITH ICON && LABEL */}

                        {/* CIRCLE BUTTON WITH ICON && LABEL (BOTTOM) */}
                        <div className="btn--circle__container">
                            <Button
                                className="btn--circle"
                                style={{ marginBottom: "0.8rem" }}
                            >
                                <Icon className="icon-run-min" />
                            </Button>
                            <PlainText className="btn--label text-center">
                                3
                            </PlainText>
                        </div>
                        {/* CIRCLE BUTTON WITH ICON && LABEL (BOTTOM) */}
                    </Col>
                </Row>
                {/* END BUTTONS */}

                {/* FORM - ELEMENTS */}
                <Row className="d-flex justify-content-center mt-5">
                    <Col lg={10}>
                        <PlainText className="mb-3 d-block">
                            Form - Elements
                        </PlainText>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center mt-5">
                    <Col lg={10}>
                        <form>
                            <FormElement
                                type="text"
                                name="name"
                                id="name"
                                placeholder="Nazwa użytkownika"
                            />
                            <FormElement
                                type="phone"
                                name="phone"
                                id="phone"
                                placeholder="Numer telefonu"
                            />
                            <FormElement
                                type="password"
                                name="password"
                                id="password"
                                placeholder="Hasło"
                            />
                            <FormElement
                                type="text"
                                name="valid"
                                id="valid"
                                placeholder="Prawidłowa wartość"
                            />
                            <FormElement
                                type="text"
                                name="invalid"
                                id="invalid"
                                placeholder="Nieprawidłowa wartość"
                                invalidMsg="Nieprawidłowa wartość"
                            />
                            <FormElement
                                type="textarea"
                                name="message"
                                id="message"
                                label="Wiadomość"
                            />
                            {/* <FormElement
                                type="select"
                                name="select"
                                id="select"
                                placeholder="Temat kontaktu"
                                options={[
                                    { label: "Opcja 1", value: "Opcja 1" },
                                    { label: "Opcja 2", value: "Opcja 2" },
                                    { label: "Opcja 3", value: "Opcja 3" },
                                ]}
                            /> */}
                            <FormElement
                                type="checkbox"
                                name="acceptance"
                                id="acceptance"
                                checked={false}
                            >
                                Lorem ipsum
                            </FormElement>
                        </form>
                    </Col>
                </Row>
                {/* END FORM - ELEMENTS */}

                {/* TYPOGRAPHY - HEADINGS */}
                <Row className="d-flex justify-content-center mt-5">
                    <Col lg={10}>
                        <PlainText className="mb-3 d-block">
                            Typography - Headings
                        </PlainText>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center mt-5">
                    <Col lg={10}>
                        <Heading className="h1" as="h1">
                            H1 Heading
                        </Heading>
                        <Heading className="h2" as="h2">
                            H2 Heading
                        </Heading>
                        <Heading className="h3" as="h3">
                            H3 Heading
                        </Heading>
                    </Col>
                </Row>
                {/* END TYPOGRAPHY - HEADINGS */}

                {/* TYPOGRAPHY - Paragraph */}
                <Row className="d-flex justify-content-center mt-5">
                    <Col lg={10}>
                        <PlainText className="mb-3 d-block">
                            Typography - Paragraph
                        </PlainText>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center mt-5">
                    <Col lg={10}>
                        <Paragraph>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Aliquam quis nulla vel lorem imperdiet semper
                            vitae eget velit. Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit.
                        </Paragraph>
                    </Col>
                </Row>
                {/* END TYPOGRAPHY - HEADINGS */}

                {/* TYPOGRAPHY - LABEL WITH ICON */}
                <Row className="d-flex justify-content-center mt-5">
                    <Col lg={10}>
                        <PlainText className="mb-3 d-block">
                            Typography - Label with icon
                        </PlainText>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center mt-5">
                    <Col lg={10}>
                        <div className="icon--with-label">
                            <Icon className="icon-pin-min" />
                            <PlainText className="icon--label">
                                Rynarzewo
                            </PlainText>
                        </div>
                    </Col>
                </Row>
                {/* END TYPOGRAPHY - LABEL WITH ICON */}

                {/* TYPOGRAPHY - TAGS */}
                <Row className="d-flex justify-content-center mt-5">
                    <Col lg={10}>
                        <PlainText className="mb-3 d-block">
                            Typography - Tags
                        </PlainText>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center mt-5">
                    <Col lg={10} className="d-flex align-items-center">
                        <Tag className="tag tag--easy">Łatwa</Tag>
                        <Tag className="tag tag--medium">Średnia</Tag>
                        <Tag className="tag tag--hard">Trudna</Tag>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col lg={10} className="d-flex align-items-center">
                        <Tag
                            group={[
                                {
                                    icon: "icon-walk-min",
                                    text: "Pieszo",
                                },
                                {
                                    icon: "icon-bicycle-min",
                                    text: "Rower",
                                },
                            ]}
                        ></Tag>
                    </Col>
                </Row>
                {/* END TYPOGRAPHY - LABEL WITH ICON */}

                {/* COMPONENTS - CARDS */}
                <Row className="d-flex justify-content-center mt-5">
                    <Col lg={10}>
                        <PlainText className="mb-3 d-block">
                            Components - Route Card
                        </PlainText>
                    </Col>
                </Row>
                <RouteCards />
                {/* <Row className="d-flex justify-content-center mt-5">
                    <Col lg={10}>
                        <PlainText className="mb-3 d-block">
                            Components - Task Card
                        </PlainText>
                    </Col>
                </Row> */}
                {/* <Row className="d-flex justify-content-center mt-5">
                    {taskData.map((task, i) => (
                        <Col key={i} md={6} lg={4}>
                            <TaskCard data={task} />
                        </Col>
                    ))}
                </Row> */}
                {/* END COMPONENTS - CARDS */}

                {/* COMPONENTS - ROUTE LIST */}
                <Row className="d-flex justify-content-center mt-5">
                    <Col lg={10}>
                        <PlainText className="mb-3 d-block">
                            Components - Route List
                        </PlainText>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center mt-5">
                    <Col lg={10}>
                        {routeTasksData.map((taskData, i) => (
                            <RouteListItem key={i} data={taskData} />
                        ))}
                    </Col>
                </Row>
                {/* END COMPONENTS - ROUTE LIST */}

                {/* COMPONENTS - MODAL */}
                <Row className="d-flex justify-content-center mt-5">
                    <Col lg={10}>
                        <PlainText className="mb-3 d-block">
                            Components - Modal
                        </PlainText>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center mt-5">
                    <Col lg={10}>
                        <Button
                            className="btn--circle"
                            onClick={() => setModal(true)}
                        >
                            <Icon className="icon-info-min" />
                        </Button>
                        <Modal
                            className="modal--sm"
                            modal={modal}
                            setModal={() => setModal(false)}
                            BottomActions={() => (
                                <>
                                    <Button class="btn--primary btn--md">
                                        Zarejestruj się
                                    </Button>
                                </>
                            )}
                        >
                            <Heading as="h2">Lorem ipsum</Heading>
                            <Heading as="h3">Proin faucibus nibh mi</Heading>
                            <Paragraph>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Fusce vitae ornare orci.
                                Praesent et est mattis, vulputate lorem
                                elementum, faucibus magna. Etiam faucibus mi
                                sagittis euismod varius. Proin faucibus nibh mi.
                                Sed vitae libero cursus nisl pharetra
                                condimentum. Praesent at sem non neque tristique
                                volutpat. Aenean porttitor vulputate placerat.
                            </Paragraph>
                            <Paragraph>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Fusce vitae ornare orci.
                                Praesent et est mattis, vulputate lorem
                                elementum, faucibus magna. Etiam faucibus mi
                                sagittis euismod varius. Proin faucibus nibh mi.
                                Sed vitae libero cursus nisl pharetra
                                condimentum. Praesent at sem non neque tristique
                                volutpat. Aenean porttitor vulputate placerat.
                            </Paragraph>
                            <Paragraph>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Fusce vitae ornare orci.
                                Praesent et est mattis, vulputate lorem
                                elementum, faucibus magna. Etiam faucibus mi
                                sagittis euismod varius. Proin faucibus nibh mi.
                                Sed vitae libero cursus nisl pharetra
                                condimentum. Praesent at sem non neque tristique
                                volutpat. Aenean porttitor vulputate placerat.
                            </Paragraph>
                        </Modal>
                    </Col>
                </Row>
                {/* END COMPONENTS - MODAL */}

                {/* COMPONENTS - PAGE HEADER */}
                <Row className="d-flex justify-content-center mt-5">
                    <Col lg={10}>
                        <PlainText className="mb-3 d-block">
                            Components - Page Header
                        </PlainText>
                    </Col>
                </Row>
                <PageHeader
                    bgImage={{
                        src: "https://images.unsplash.com/photo-1511576661531-b34d7da5d0bb?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
                        alt: "sample-image",
                    }}
                />
                {/* END COMPONENTS - PAGE HEADER */}
            </Container>
        </Layout>
    );
};

export default Sandbox;
