import React from "react";
import classnames from "classnames";
import axios from "axios";

// STYLE
import "./routeCard.scss";

import { useDispatch } from "react-redux";
import { navigate } from "gatsby";
import { logOut } from "../../../state/actions";

import Icon from "../../atoms/Icon/Icon";
import PlainText from "../../atoms/PlainText/PlainText";
import Tag from "../../atoms/Tag/Tag";
import Heading from "../../atoms/Heading/Heading";
import Button from "../../atoms/Button/Button";
import Link from "../../atoms/Link/Link";
import RouteCardTags from "../RouteCardTags/RouteCardTags";
import { useAuth, useRegisterModal, useRoutes } from "../../../hooks/redux";

const difficultyToClassName = (difficulty) =>
    difficulty === "Łatwy"
        ? "easy"
        : difficulty === "Średni"
            ? "medium"
            : difficulty === "Trudny"
                ? "hard"
                : undefined;

const RouteCard = ({ className, route, dragging, isRouteView }) => {
    const dispatch = useDispatch();
    const [auth, { updateFavorites }] = useAuth();
    const [routes, { setRoute }] = useRoutes();
    const [modal, { showRegisterModal, setUrlRegisterModal }] =
        useRegisterModal();

    const isRouteFavorited = (id) =>
        auth?.data?.favorites?.includes(id) ?? false;

    const onFavoriteClick = (id) => async (event) => {
        if (auth.isLoggedIn) {
            if (isRouteFavorited(id)) {
                try {
                    const response = await axios.delete(
                        `${process.env.STRAPI_API_URL}/users/me/favorites/${id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${auth.data.jwt}`,
                            },
                        }
                    );

                    setRoute(response.data.route);
                    updateFavorites(response.data.user.favorites);
                } catch (error) {
                    console.log(error);
                    const { status } = error.response;

                    if (status === 401 || status === 403 || status === 405) {
                        dispatch(logOut());
                        navigate("/app/logowanie");
                    }
                }
            } else {
                try {
                    const response = await axios.post(
                        `${process.env.STRAPI_API_URL}/users/me/favorites/${id}`,
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${auth.data.jwt}`,
                            },
                        }
                    );

                    setRoute(response.data.route);
                    updateFavorites(response.data.user.favorites);
                } catch (error) {
                    console.log(error);
                    const { status } = error.response;

                    if (status === 401 || status === 403 || status === 405) {
                        dispatch(logOut());
                        navigate("/app/logowanie");
                    }
                }
            }
        } else {
            setUrlRegisterModal("");
            showRegisterModal();
        }
    };

    return (
        <div className={classnames(`route-card route-card--${difficultyToClassName(route?.difficulty?.name)}`, className)}>
            <button
                className="btn clickable-area"
                onClick={(e) => {
                    e.target.blur();
                    if (!dragging && document) {
                        if (isRouteView) {
                            document.body.classList.add("blink");
                            setTimeout(() => {
                                navigate(`/trasy/${route?.id}`);
                            }, 100);
                        } else {
                            navigate(`/trasy/${route?.id}`);
                        }
                    }
                }}
                aria-label={`Nazwa trasy: ${route?.name}`}
            ></button>
            <div className="route-card--top-container">
                <img
                    className="img-fluid"
                    src={process.env.STRAPI_API_URL + route?.thumb?.url}
                    alt=""
                />
                <Button
                    className="btn--secondary__sm"
                    onClick={onFavoriteClick(route?.id)}
                    aria-label={`Licznik polubień: ${route?.favoritescount}`}
                >
                    <Icon
                        className={
                            isRouteFavorited(route?.id)
                                ? "icon-heart-filled-min favorited"
                                : "icon-heart-min"
                        }
                    />
                    <PlainText className="btn--label">
                        {route?.favoritescount}
                    </PlainText>
                </Button>
                <Button
                    className="btn--secondary__sm label"
                    onClick={() => navigate(`/trasy/${route?.id}`)}
                >
                    <PlainText className="btn--label">
                        Przejdź do trasy
                        <Icon className="icon-eye-min" />
                    </PlainText>
                </Button>
                <Tag
                    className={classnames(
                        "tag",
                        `tag--${difficultyToClassName(route?.difficulty?.name)}`
                    )}
                >
                    {route?.difficulty?.name}
                </Tag>
            </div>
            <div className="route-card--content-container">
                <Heading className="h3" as="h3">
                    {route?.name}
                </Heading>
                <RouteCardTags route={route} />
            </div>
        </div>
    );
};

export default RouteCard;
